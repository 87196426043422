export const API_URL = 'https://api.ahmadsadek.com/';

// api call helper function
export const apiCall = async (method, path, data) => {
    const body = method !== 'GET' ? JSON.stringify(data) : null;

    const ipData = await fetch('https://geolocation-db.com/json/').then(res => res.json()).catch(err => console.log(err));
    const response = await fetch(API_URL + 'api' + path, {
        method,
        headers: {
            'Accept': 'application/json',
	    'X-Client-IPv4': ipData.IPv4,
	    'X-Client-Country': ipData.country_name,
	    'X-Client-City': ipData.city,
            'Content-Type': 'application/json'
        },
        body
    }).then(res => res.json()).catch(err => console.log(err));

    return response;
}

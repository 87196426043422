import { useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { apiCall } from './api';

const Canvas = () => {
    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const [isDrawing, setIsDrawing] = useState(false);
    const [prediction, setPrediction] = useState(null);
    const [predictionWrong, setPredictionWrong] = useState(false);
    const [number, setNumber] = useState("");

    useEffect(() => {
        const canvas = canvasRef.current;

        canvas.width = window.innerWidth < 400 ? 250 : 300;
        canvas.height = window.innerWidth < 400 ? 250 : 300;

        const context = canvas.getContext('2d');
        context.lineCap = 'round';
        context.strokeStyle = 'black';
        context.lineWidth = window.innerWidth < 400 ? 20 : 25;
        context.fillStyle = "#FFF";
        context.fillRect(0, 0, canvas.width, canvas.height);

        contextRef.current = context;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startDrawing = ({nativeEvent}) => {
        const { offsetX, offsetY } = nativeEvent;

        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();

        setIsDrawing(true);
        nativeEvent.preventDefault();
    }

    const onTouchStart = (e) => {
        contextRef.current.beginPath();
        
        setIsDrawing(true);
        return false;
    }

    const draw = ({nativeEvent}) => {
        if (!isDrawing) return;

        const { offsetX, offsetY } = nativeEvent;

        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
        nativeEvent.preventDefault();
    }

    const onTouchMove = (e) => {
        contextRef.current.lineTo(e.touches[0].pageX - 70, e.touches[0].pageY - window.innerWidth / 3);
        contextRef.current.stroke();
    }

    const stopDrawing = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    }

    const onTouchEnd = (e) => {
        contextRef.current.closePath();
        setIsDrawing(false);
    }

    const clear = () => {
        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        contextRef.current.fillStyle = "#FFF";
        contextRef.current.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        setPrediction(null);
    }

    const yesNoClicked = (yes) => {
        if (yes) {
            toast.success("Thank you for your feedback!");
            clear();
        }
        else setPredictionWrong(true);
    }

    const submitImage = async () => {
        const toastId = toast.loading("Loading...");
        const image = canvasRef.current.toDataURL("image/png");
        // var newTab = window.open('about:blank','image from canvas');
        // newTab.document.write("<img src='" + image + "' alt='from canvas'/>");
        // window.location.href=image;

        const data = {
            image_base64: image,
        }

        const response = await apiCall('POST', '/predict', data);

        toast.dismiss(toastId);

        if (response?.status === 200) {
            toast.success("Prediction Successful!");
            setPrediction(response.prediction);
        }
    }

    const submitCorrectValue = async () => {
        const regex=/^[0-9]+$/;

        if (!number.match(regex)) {
            return toast.error("Please enter a number.");
        }

        const num = parseInt(number);
        
        if (num < 0 || num > 9) {
            return toast.error("Please enter a number between 0 and 9.");
        }

        const toastId = toast.loading("Loading...");
        const image = canvasRef.current.toDataURL("image/png");

        const data = {
            image_base64: image,
            num
        }

        const response = await apiCall('POST', '/correct_prediction', data);
        
        toast.dismiss(toastId);

        if (response?.status === 200) {
            toast.success("Added to data query!");
        }

        toast.success("Thank you for your feedback, I will try to learn more and improve!");
        setPredictionWrong(false);
        setPrediction(null);
        setNumber("");
        clear();
    }

    return (
        <div className="main__container">
            <h5>I am an A.I. that can detect handwritten digits</h5>
            <h6>Draw a number and test me!</h6>

            <canvas
                className="canvas__container"
                ref={canvasRef}
                onMouseDown={startDrawing}
                onTouchStart={onTouchStart}
                onMouseMove={draw}
                onTouchMove={onTouchMove}
                onMouseUp={stopDrawing}
                onTouchEnd={onTouchEnd}
                onMouseLeave={stopDrawing}
                />
            
            <div className="footer__container">

                {prediction && <h3>I think you wrote: {prediction}</h3>}

                {prediction && (
                <>
                    {!predictionWrong ? <h2>Correct?</h2> : <h4>Please enter the correct value</h4>}
                    <div className="yes_no__container">

                        {!predictionWrong && (
                            <>
                                <button
                                    onClick={() => yesNoClicked(true)}
                                    type="submit"
                                    className="yes_no__btn" >Yes</button>
                                <button
                                    onClick={() => yesNoClicked(false)}
                                    type="submit"
                                    className="yes_no__btn" >No</button>
                            </>
                        )}

                        {predictionWrong && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Correct value"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                    />
                                <button
                                    onClick={submitCorrectValue}
                                    type="submit"
                                    className="yes_no__btn" >Submit</button>
                            </>
                        )}

                    </div>
                </>
                )}

                {!prediction && (
                <>
                    <button
                        onClick={clear}
                        type="submit"
                        className="submit__btn" >Clear</button>
                    <button
                        onClick={submitImage}
                        type="submit"
                        className="submit__btn" >Submit</button>
                </>
                )}

            </div>
            <Toaster />
        </div>
    )
}

export default Canvas

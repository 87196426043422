import React from 'react'
import Canvas from './Canvas'

function App() {
  return (
    <div className="app">
      <Canvas />
    </div>
  )
}

export default App